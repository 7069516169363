.navbar {
    box-shadow: -2px 12px 31px -11px rgba(0, 0, 0, 0.12);
}

.navbar-logo {
    width: 150px;
}

#root {
    padding: 0;
    background: radial-gradient(100.08% 100.08% at 0% 0%, rgba(42, 91, 154, 0.1652) 0%, rgba(42, 91, 154, 0) 100%);
}

.hero {
    display: flex;
    margin-bottom: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hero-title-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hero-title {
    display: flex;
    align-items: center;
}

.hero-image {
    width: 800px;
    z-index: -1;
    object-fit: cover;
}



.title {
    font-size: 60px;
    width: 700px;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.subtitle {
    margin-top: 30px;
    font-size: 25px;
    color: rgb(68, 68, 68);
    font-weight: 400;
}

.title-btn {
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: bold;
    font-size: 12px;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: -2px 0px 38px 5px rgba(0, 0, 0, 0.18);
    background-color: #2A5B9A;
}

.title-btn:hover {
    background-color: #416ea8;
}

.info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 200px;
    margin: 0;
    width: 100%;
}

.card {
    box-shadow: -2px 0px 38px 5px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    border: 0px;
    padding: 20px;
}

.card-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
}

.card-icon {
    padding: 20px;
}

.card-title {
    text-align: center;
}

.card-text {
    text-align: center;
    font-size: 15px;
    color: rgb(68, 68, 68);
}

.card-btn {
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width: 1500px) {
    .hero-image {
        display: none;
    }

    .hero-title {
        margin-top: 100px;
        text-align: center;
    }

    .hero-title-text {
        align-items: center;
    }

}

@media only screen and (max-width: 1000px) {
    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 600px;
    }

}

@media only screen and (max-width: 620px) {
    .title {
        font-size: 30px;
        width: 300px;
    }

    .subtitle {
        font-size: 15px;
    }

    .title-btn {
        font-size: 10px;
        padding: 20px;
    }

    .card {
        width: 300px;
    }

}

/* Footer */

.footer {
    margin: 250px 0px 25px 0px;
}

.footer-text {
    margin-top: 20px;
}

.separator {
    border-top: 1px solid #6e6e6e;
    opacity: 40%;
    height: 1px;
}

/* About Page */
.about-hero {
    margin-top: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.about {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.about-img {
    background-size: cover;
    width: 650px;
}

.about-title {
    font-size: 40px;
    align-self: flex-end;
    color: rgb(51, 51, 51);
    font-weight: bold;
    text-align: center;
}

.about-subtitle {
    font-weight: 200;
    font-size: 30px;
    align-self: flex-end;
    text-align: end;
    color: rgba(0, 0, 0, 1);
}

.about-info-text {
    align-self: flex-end;
    text-align: end;
    font-size: 22px;
    font-weight: 350;
    margin-top: 20px;
}





@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1000px) {
    .about-hero {
        display: flex;
    }

    .about-img {
        display: none;
    }

    .about-hero {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about {
        display: flex;
        align-items: center;
    }

    .about-title {
        text-align: center;
        font-size: 30px;
    }

    .about-subtitle {
        font-size: 20px;
        text-align: center;
        align-self: center;
    }

    .about-info-text {
        font-size: 15px;
        width: 500px;
        text-align: center;
    }

}

@media only screen and (max-width: 620px) {

    .about-title {
        text-align: center;
        font-size: 20px;
    }

    .about-subtitle {
        font-size: 15px;
    }

    .about-info-text {
        font-size: 12px;
        padding: 0 120px;
        text-align: center;
    }
}


/* Contact Page */

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-title {

    padding: 100px;
    font-size: 70px;
    align-self: center;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.contact-info {
    display: flex;
    gap: 120px;
}

.contact-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.item-icon {
    font-size: 80px;
    border: 2px solid #2A5B9A;
    background-color: #2A5B9A;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.item-location {
    transition: 300ms ease-in-out;
}

.item-location:hover {
    transform: scale(1.1);
}

.item-text {
    text-align: center;
    font-weight: 400;
    font-size: larger;
}

@media only screen and (max-width: 1000px) {
    .contact-info {
        flex-direction: column;

    }

    .contact-title {
        font-size: 35px;
    }

}

@media only screen and (max-width: 620px) {
    .contact-info {
        flex-direction: column;
        gap: 30px;
    }
    .contact-title {
        padding: 20px;
        margin: 100px 0;
        font-size: 20px;
    }
    .item-icon {
        padding: 15px;
        font-size: 60px;
    }
    .item-text {
        font-size: 15px;
    }
}


/* CustomExample Page */
.custom-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 200px;
    gap: 40px;
}

.custom-title {
    padding: 0;
    margin: 100px 0 50px 0;
    font-size: 50px;
    align-self: center;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.custom-solution {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 20px;
    align-items: center;
    gap: 60px;
    justify-content: center;
    grid-row-gap: 150px;
}

.custom-info-subtitle {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 19px;
}

.solution-image {
    height: 400px;
    background-size: cover;
}

.solution-info {
    display: flex;
    flex-direction: column;
}

.custom-info-list {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    gap: 25px;
    list-style-type: none;
}

.custom-info-text {
    padding-left: 20px;
    font-size: 18px;
    color: rgb(51, 51, 51);
    font-weight: lighter;
    display: flex;
    align-items: center;
    gap: 20px;
}

.separator-sm {
    border-top: 1px solid #6e6e6e;
    opacity: 40%;
    width: 100px;
    height: 1px;
}

.custom-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.custom-contact-btn {
    padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: lighter;
    font-size: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    transition: 300ms ease-in-out;
}

.custom-contact-btn:hover {
    color: white;
    transform: scale(1.1);
}


.custom-contact-title {
    padding: 0;
    margin-bottom: 50px;
    font-size: 45px;
    align-self: center;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

@media only screen and (max-width: 1500px) {
    .solution-image {
        display: none;
    }
    .custom-solution {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .custom-title {
        font-size: 30px;
        padding: 15px;
        margin: 20px 0;
        text-align: center;
    }
    .custom-info-subtitle {
        font-size: 15px;
    }
    .custom-info-text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 620px) {
    .custom-title {
        font-size: 20px;
        padding: 15px;
        margin: 20px 0;
        text-align: center;
    }

    .custom-hero {
        margin: 0;
    }

    .custom-contact-btn {
        padding: 0px;
    }

    .custom-contact-title {
        font-size: 25px;
        margin-top: 100px;
    }
}

/* CloudExample Page */

.cloud-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 100px 0;
    transition: 500ms ease-in-out;
}

.cloud-solution:hover {
    transform: scale(1.25);
}

.cloud-hero-title {
    width: 1000px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: #3a3a3a;
}

.cloud-solutions {
    margin: 200px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 150px;
}

.cloud-solution {
    color: #3a3a3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    transition: 500ms ease-in-out;
}

.solution-title {
    font-weight: 300;
    font-size: 35px;
}

.solution-text {
    width: 500px;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 1500px) {
    .cloud-solutions {
        margin: 0;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .cloud-hero-title {
        font-size: 25px;
        padding: 0 200px;
    }
}

@media only screen and (max-width: 620px) {
    .cloud-hero-title {
        font-size: 15px;
        padding: 0 350px;
    }

    .cloud-solutions {
        align-items: center;
    }

    .solution-title {
        font-size: 20px;
    }

    .solution-text {
        font-size: 12px;
        padding: 0 100px;
    }
}